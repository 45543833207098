exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-allergy-js": () => import("./../../../src/pages/allergy.js" /* webpackChunkName: "component---src-pages-allergy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-misc-js": () => import("./../../../src/pages/misc.js" /* webpackChunkName: "component---src-pages-misc-js" */),
  "component---src-templates-area-template-js": () => import("./../../../src/templates/AreaTemplate.js" /* webpackChunkName: "component---src-templates-area-template-js" */),
  "component---src-templates-city-template-js": () => import("./../../../src/templates/CityTemplate.js" /* webpackChunkName: "component---src-templates-city-template-js" */),
  "component---src-templates-listing-template-js": () => import("./../../../src/templates/ListingTemplate.js" /* webpackChunkName: "component---src-templates-listing-template-js" */)
}

